.container {
  width: 100%;
  max-width: 79.167vw;
  margin: 0 auto;
  padding: 0;
}

@media (width <= 1439px) {
  .brands-partner-logo {
    padding: 0 20px;
  }
}

@media (width <= 1199px) {
  .container {
    max-width: 87.167vw;
  }

  .header-logo img {
    width: 109px;
  }

  .header-logo {
    padding: 30px 0 55px;
  }

  .banner-section .container {
    max-width: 70.167vw;
  }

  .brands-partner-logo {
    padding: 0 20px;
  }

  .divider-dash-line {
    width: 68.861vw;
  }

  .data-info-section {
    padding: 6.681vw 0 5.528vw;
  }

  .data-info-section .container {
    max-width: 80.958vw;
  }

  .data-info-number-list .icon {
    width: 30px;
    margin-right: 30px;
  }

  .data-info-wrapper + .data-info-wrapper {
    padding-top: 75px;
  }

  .data-info-left {
    width: 29.917vw;
    margin-left: 2vw;
  }

  .data-info-wrapper + .data-info-wrapper .data-info-left {
    margin-left: 0;
    margin-right: 2vw;
  }

  .digital-marketing-tabs-content {
    margin: 0 0 0 7.361vw;
  }
}

@media (width <= 1023px) {
  .header-logo {
    padding: 20px 0 45px;
  }

  .container {
    max-width: 100%;
    padding: 0 40px;
  }

  .banner-section .container {
    max-width: 100%;
    padding: 0 50px;
  }

  h1 {
    font-size: 56px;
    line-height: 60px;
  }

  .banner-wrapper p {
    width: 100%;
    margin: 0 auto 45px;
    font-size: 18px;
    line-height: 30px;
  }

  .scroll-next-btn img {
    width: 60px;
  }

  .banner-wrapper h1 {
    margin-bottom: 45px;
  }

  .marketing-solutions-img {
    width: 45%;
  }

  .marketing-solutions-info {
    width: 48%;
  }

  .marketing-solutions-info h2 {
    margin: 0 0 18px;
    font-size: 35px;
    line-height: 41px;
  }

  .marketing-solutions-info h4 {
    margin: 0 0 15px;
    font-size: 16px;
    line-height: 23px;
  }

  .marketing-solutions-info p {
    padding-right: 0;
    font-size: 14px;
    line-height: 22px;
  }

  .btn {
    border: 1px solid #f2f2f2;
    border-radius: 45px;
    min-width: 160px;
    height: 50px;
    font-size: 14px;
  }

  .divider-dash-line {
    width: 100%;
  }

  .data-info-section .container {
    max-width: 100%;
  }

  .data-info-left {
    width: 33.917vw;
  }

  .data-info-right {
    width: 36.625vw;
  }

  .data-info-wrapper + .data-info-wrapper .data-info-right {
    width: 40.625vw;
  }

  .data-info-right h2 {
    letter-spacing: 1px;
    margin: 0 0 25px;
    font-size: 36px;
    line-height: 42px;
  }

  .data-info-right p {
    margin: 0 0 15px;
    padding-right: 0;
    font-size: 14px;
    line-height: 21px;
  }

  .digital-marketing-tabs-nav {
    z-index: 1;
    border-radius: 20px;
    width: 50%;
    padding: 25px;
    position: relative;
  }

  .digital-marketing-tabs-nav-info {
    z-index: 0;
    border-radius: 20px;
    width: 55%;
    height: 100%;
    padding: 25px;
    top: 0;
    right: 0;
  }

  .nav-tabs .nav-item a {
    font-size: 14px;
    line-height: 1.3;
  }

  .digital-marketing-tabs-content ul li {
    margin: 0 0 15px;
    padding: 0 0 0 25px;
    font-size: 14px;
    line-height: 1.3;
  }

  .digital-marketing-tabs-content {
    width: 100%;
    margin: 0;
    padding: 0 0 0 40px;
  }

  .nav-tabs .nav-item a.active:before {
    width: 23px;
    height: 23px;
    right: -4.3vw;
  }

  .digital-marketing-tabs-content ul li ul li {
    width: 100%;
    margin: 5px 0 0;
    padding-left: 0;
    font-size: 14px;
    list-style-type: disc;
  }

  .digital-marketing-tabs-content ul li ul {
    margin-left: 15px;
  }

  .nav-tabs .nav-item:not(:last-child) {
    margin-bottom: 50px;
  }

  .digital-marketing-wrapper h2 {
    margin: 0 0 35px;
  }

  .brands-partner-logo {
    padding: 0 10px;
  }
}

@media (width <= 767px) {
  .header-logo img {
    width: 90px;
  }

  .banner-section .container {
    max-width: 100%;
    padding: 0 60px;
  }

  h1 {
    font-size: 42px;
    line-height: 53px;
  }

  .banner-wrapper h1 {
    margin-bottom: 25px;
  }

  .marketing-solutions-info h2 {
    margin: 0 0 25px;
    font-size: 31px;
    line-height: 38px;
  }

  .container {
    max-width: 100%;
    padding: 0 20px;
  }

  .brands-partner-wrapper h4 {
    margin: 0 0 35px;
    font-size: 18px;
    line-height: 30px;
  }

  .brands-partner-logo {
    padding: 0 12px;
  }

  .data-number span {
    font-size: 36px;
    line-height: 1.3;
  }

  .data-number p {
    margin: 0;
    font-size: 12px;
    line-height: 1.2;
  }

  .data-info-number-list .icon {
    width: 30px;
    margin-right: 20px;
  }

  .data-info-left {
    width: 45%;
    margin-left: 0;
  }

  .data-info-right {
    width: 48%;
  }

  .data-info-wrapper + .data-info-wrapper {
    padding-top: 40px;
  }

  .data-info-section {
    padding: 45px 0;
  }

  .testimonials-section {
    padding: 50px 0;
  }

  .testimonials-wrapper h2 {
    margin: 0 0 25px;
    font-size: 31px;
    line-height: 1.3;
  }

  .testimonials-slider {
    width: 90%;
    margin: 0 auto;
    padding: 0 0 65px;
    position: relative;
  }

  .testimonials-slide p {
    letter-spacing: 0;
    font-size: 16px;
    line-height: 26px;
  }

  .testimonials-slider .swiper-button-next, .testimonials-slider .swiper-button-prev {
    width: 45px;
    height: 45px;
  }

  .testimonials-slider:after {
    right: -35px;
  }

  .digital-marketing-wrapper h2 {
    margin: 0 0 25px;
    font-size: 31px;
    line-height: 1.3;
  }

  .digital-marketing-tabs-nav {
    border-radius: 15px;
    width: 50%;
    padding: 25px;
  }

  .digital-marketing-tabs-nav-info {
    border-radius: 15px;
    width: 55%;
    padding: 20px;
  }

  .nav-tabs .nav-item a {
    font-size: 14px;
    line-height: 21px;
  }

  .nav-tabs .nav-item:not(:last-child) {
    margin-bottom: 25px;
  }

  .digital-marketing-tabs-content ul li {
    margin: 0 0 10px;
    padding: 0 0 0 25px;
    font-size: 14px;
    line-height: 17px;
  }

  .digital-marketing-tabs-content ul li:before {
    width: 16px;
    height: 16px;
  }

  .digital-marketing-tabs-content {
    width: 100%;
    margin: 0;
    padding: 0 0 0 25px;
  }

  .digital-marketing-tabs-content ul li ul li {
    width: 100%;
    margin: 5px 0 0;
    padding-left: 0;
    font-size: 12px;
    list-style-type: disc;
  }

  .nav-tabs .nav-item a.active:before {
    width: 20px;
    height: 20px;
    top: 9px;
    right: -4.3vw;
  }

  h2 {
    letter-spacing: 0;
    font-size: 31px;
    line-height: 40px;
  }

  .ready-section {
    padding: 50px 0;
  }

  .data-info-wrapper + .data-info-wrapper .data-info-left {
    margin-left: 0;
    margin-right: 0;
  }

  .data-info-right p br {
    display: none;
  }
}

@media (width <= 575px) {
  .banner-section .container {
    max-width: 100%;
    padding: 0 15px;
  }

  .banner-wrapper {
    text-align: center;
    width: 325px;
    margin: 0 auto;
  }

  h1 {
    letter-spacing: 0;
    font-size: 31px;
    line-height: 39px;
  }

  .banner-wrapper h1 {
    margin-bottom: 20px;
  }

  .banner-wrapper p {
    width: 100%;
    margin: 0 auto 40px;
    font-size: 16px;
    line-height: 26px;
  }

  .banner-section {
    padding-bottom: 45px;
  }

  .marketing-solutions-wrapper {
    justify-content: center;
  }

  .marketing-solutions-img {
    width: 60%;
    margin: 0 auto 35px;
  }

  .marketing-solutions-section {
    padding: 40px 0;
  }

  .marketing-solutions-info {
    text-align: center;
    width: 100%;
  }

  .brands-partner-row {
    flex-wrap: wrap;
    justify-content: center;
  }

  .brands-partner-logo {
    width: 33.33%;
    padding: 0 12px 25px;
  }

  .brands-partner-wrapper h4 {
    margin: 0 0 25px;
    font-size: 20px;
    line-height: 30px;
  }

  .data-info-section .container {
    max-width: 100%;
  }

  .data-info-wrapper {
    justify-content: center;
    top: 0;
    transform: scale(1) !important;
  }

  .data-info-left {
    order: -1;
    width: 250px;
    margin: 0 0 35px;
  }

  .data-info-right {
    text-align: center;
    order: -2;
    width: 100%;
    margin: 0 0 15px;
  }

  .data-number {
    text-align: right;
    width: calc(100% - 50px);
  }

  .data-info-right h2 {
    letter-spacing: 1px;
    margin: 0 0 15px;
    font-size: 28px;
    line-height: 38px;
  }

  .data-info-wrapper + .data-info-wrapper .data-info-right {
    width: 100%;
  }

  .testimonials-section {
    padding: 40px 0;
  }

  .testimonials-slide {
    padding: 0 15px;
  }

  .testimonials-slider {
    width: 100%;
  }

  .container {
    max-width: 100%;
    padding: 0 15px;
  }

  .testimonials-slider .swiper-scrollbar {
    border-radius: 10px;
    width: 80%;
    height: 3px;
    margin-top: 25px;
  }

  .testimonials-slider:before {
    width: 35px;
    height: 60px;
    top: -20px;
    left: -5px;
  }

  .testimonials-slider:after {
    width: 35px;
    height: 60px;
    bottom: 50px;
    right: -5px;
  }

  .testimonials-slider .swiper-button-next {
    left: 59%;
  }

  .testimonials-slider .swiper-button-prev {
    left: 41%;
  }

  .digital-marketing-tabs-nav-info {
    border-radius: 15px;
    width: 100%;
    height: auto;
    margin-top: -22px;
    padding: 55px 20px 20px;
    position: relative;
  }

  .digital-marketing-tabs-nav {
    border-radius: 15px;
    width: 100%;
    padding: 18px 15px;
  }

  .digital-marketing-tabs-content {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  a.tabs-dropdown {
    color: #fff;
    text-align: left;
    padding: 0 20px 0 0;
    font-family: Gotham Book;
    font-size: 14px;
    font-weight: normal;
    display: block;
    position: relative;
  }

  a.tabs-dropdown:before {
    content: "";
    border: 0;
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
    width: 8px;
    height: 8px;
    position: absolute;
    top: 5px;
    right: 0;
    transform: rotate(-135deg);
  }

  .nav-tabs {
    border-top: 1px dashed #ffffff45;
    margin: 20px 0 0;
    padding-top: 20px;
    display: none;
  }

  .digital-marketing-tabs-content ul li {
    margin: 0 0 20px;
  }

  a.tabs-dropdown.open:before {
    top: 10px;
    transform: rotate(45deg);
  }

  .ready-section {
    padding: 40px 0;
  }

  .ready-wrapper h2 span br {
    display: none;
  }

  h2 {
    letter-spacing: 0;
    font-size: 28px;
    line-height: 37px;
  }

  .ready-wrapper h2 {
    margin: 0 0 25px;
  }

  .hidemobile {
    display: none;
  }

  .hidedesktop {
    display: block;
  }

  ul.nav.nav-tabs.mobile-tabs {
    z-index: 1;
    background: #32327a;
    position: absolute;
    left: 0;
    right: 0;
    transform: translateY(-2px);
  }

  .digital-marketing-tabs-nav.digital-marketing-tabs-active {
    border-radius: 15px 15px 0 0;
  }

  .nav-tabs .nav-item a {
    padding: 0 15px;
  }

  .nav-tabs .nav-item a.active:before {
    display: none;
  }

  .digital-marketing-tabs-nav-info .tab-content > .tab-pane {
    transition: unset !important;
  }

  .data-info-wrapper-d-flex {
    justify-content: center;
    padding: 20px 15px;
  }

  .data-info-wrapper + .data-info-wrapper {
    transform: translateY(0);
  }

  .data-info-right p br, .digital-marketing-wrapper .heading-animate span br {
    display: none;
  }

  .brands-partner-logo img {
    max-height: 50px;
  }

  .find-out-btn {
    margin: 0;
    padding-top: 25px;
    padding-bottom: 45px;
  }

  .data-info-right p strong {
    font-size: 22px;
  }

  .data-info-section {
    background: none;
    padding: 0;
  }

  .data-info-section .container {
    padding: 0;
  }

  .brands-partner-row.mobile-hide {
    display: none;
  }

  .brands-partner-row.desktop-hide {
    background: #000;
    margin: -1px 0 0;
    padding: 20px 15px 0;
    display: flex;
  }

  .brands-partner-section {
    padding: 0;
  }

  .data-number p.align-left {
    text-align: right;
  }

  .contact-form input, .contact-form select, .contact-form textarea, #mktChallengesPlaceholder {
    padding: 1rem 1.25rem;
    font-size: 1rem;
  }

  .multiple-select-label, .custom-checkbox {
    font-size: 1rem;
  }

  .custom-checkbox label {
    column-gap: 1rem;
  }

  .custom-checkbox .checkmark {
    flex: 0 0 32px;
    height: 32px;
  }

  .multiple-select-content {
    padding: 1.125rem 1.25rem;
  }

  .multiple-select-content > div:not(:last-child) {
    margin-bottom: 1rem;
  }

  .submit-button {
    min-height: 51px;
    font-size: 18px;
  }
}

@media (width >= 577px) {
  .modal-dialog {
    max-width: 600px;
  }
}

@media (width >= 768px) {
  .contact-form {
    width: calc(100% - 6rem);
    margin: 0 auto;
  }
}
/*# sourceMappingURL=index.f5846e91.css.map */
