.container {
	max-width: 79.167vw;
	margin: 0 auto;
	padding: 0;
	width: 100%;
}

@media (max-width: 1439px) {
	.brands-partner-logo {
		padding: 0 20px;
	}
}

@media (max-width: 1199px) {
	.container {
		max-width: 87.167vw;
	}
	.header-logo img {
		width: 109px;
	}
	.header-logo {
		padding: 30px 0 55px;
	}
	.banner-section .container {
		max-width: 70.167vw;
	}
	.brands-partner-logo {
		padding: 0 20px;
	}
	.divider-dash-line {
		width: 68.861vw;
	}
	.data-info-section {
		padding: 6.681vw 0 5.528vw;
	}
	.data-info-section .container {
		max-width: 80.958vw;
	}
	.data-info-number-list .icon {
		margin-right: 30px;
		width: 30px;
	}
	.data-info-wrapper + .data-info-wrapper {
		padding-top: 75px;
	}
	.data-info-left {
		margin-left: 2vw;
		width: 29.917vw;
	}
	.data-info-wrapper + .data-info-wrapper .data-info-left {
		margin-left: 0;
		margin-right: 2vw;
	}
	.digital-marketing-tabs-content {
		margin: 0 0 0 7.361vw;
	}
}

@media (max-width: 1023px) {
	.header-logo {
		padding: 20px 0 45px;
	}
	.container {
		max-width: 100%;
		padding: 0 40px;
	}
	.banner-section .container {
		padding: 0 50px;
		max-width: 100%;
	}
	h1 {
		font-size: 56px;
		line-height: 60px;
	}
	.banner-wrapper p {
		width: 100%;
		margin: 0 auto 45px;
		font-size: 18px;
		line-height: 30px;
	}
	.scroll-next-btn img {
		width: 60px;
	}
	.banner-wrapper h1 {
		margin-bottom: 45px;
	}
	.marketing-solutions-img {
		width: 45%;
	}
	.marketing-solutions-info {
		width: 48%;
	}
	.marketing-solutions-info h2 {
		font-size: 35px;
		line-height: 41px;
		margin: 0 0 18px;
	}
	.marketing-solutions-info h4 {
		font-size: 16px;
		line-height: 23px;
		margin: 0 0 15px;
	}
	.marketing-solutions-info p {
		padding-right: 0;
		font-size: 14px;
		line-height: 22px;
	}
	.btn {
		border: 1px solid #f2f2f2;
		min-width: 160px;
		height: 50px;
		font-size: 14px;
		border-radius: 45px;
	}
	.divider-dash-line {
		width: 100%;
	}
	.data-info-section .container {
		max-width: 100%;
	}
	.data-info-left {
		width: 33.917vw;
	}
	.data-info-right {
		width: 36.625vw;
	}
	.data-info-wrapper + .data-info-wrapper .data-info-right {
		width: 40.625vw;
	}
	.data-info-right h2 {
		font-size: 36px;
		line-height: 42px;
		letter-spacing: 1px;
		margin: 0 0 25px;
	}
	.data-info-right p {
		font-size: 14px;
		line-height: 21px;
		margin: 0 0 15px;
		padding-right: 0;
	}
	.digital-marketing-tabs-nav {
		width: 50%;
		border-radius: 20px;
		padding: 25px;
		z-index: 1;
		position: relative;
	}
	.digital-marketing-tabs-nav-info {
		width: 55%;
		right: 0;
		top: 0;
		height: 100%;
		border-radius: 20px;
		z-index: 0;
		padding: 25px;
	}
	.nav-tabs .nav-item a {
		font-size: 14px;
		line-height: 1.3;
	}
	.digital-marketing-tabs-content ul li {
		margin: 0 0 15px;
		padding: 0 0 0 25px;
		font-size: 14px;
		line-height: 1.3;
	}
	.digital-marketing-tabs-content {
		width: 100%;
		margin: 0;
		padding: 0 0 0 40px;
	}
	.nav-tabs .nav-item a.active::before {
		right: -4.3vw;
		width: 23px;
		height: 23px;
	}
	.digital-marketing-tabs-content ul li ul li {
		padding-left: 0;
		width: 100%;
		list-style-type: disc;
		margin: 5px 0 0;
		font-size: 14px;
	}
	.digital-marketing-tabs-content ul li ul {
		margin-left: 15px;
	}
	.nav-tabs .nav-item:not(:last-child) {
		margin-bottom: 50px;
	}
	.digital-marketing-wrapper h2 {
		margin: 0 0 35px;
	}
	.brands-partner-logo {
		padding: 0 10px;
	}
}

@media (max-width: 767px) {
	.header-logo img {
		width: 90px;
	}
	.banner-section .container {
		padding: 0 60px;
		max-width: 100%;
	}
	h1 {
		font-size: 42px;
		line-height: 53px;
	}
	.banner-wrapper h1 {
		margin-bottom: 25px;
	}
	.marketing-solutions-info h2 {
		font-size: 31px;
		line-height: 38px;
		margin: 0 0 25px;
	}
	.container {
		max-width: 100%;
		padding: 0 20px;
	}
	.brands-partner-wrapper h4 {
		font-size: 18px;
		line-height: 30px;
		margin: 0 0 35px;
	}
	.brands-partner-logo {
		padding: 0 12px;
	}
	.data-number span {
		font-size: 36px;
		line-height: 1.3;
	}
	.data-number p {
		font-size: 12px;
		line-height: 1.2;
		margin: 0;
	}
	.data-info-number-list .icon {
		margin-right: 20px;
		width: 30px;
	}
	.data-info-left {
		width: 45%;
		margin-left: 0;
	}
	.data-info-right {
		width: 48%;
	}
	.data-info-wrapper + .data-info-wrapper {
		padding-top: 40px;
	}
	.data-info-section {
		padding: 45px 0;
	}
	.testimonials-section {
		padding: 50px 0;
	}
	.testimonials-wrapper h2 {
		font-size: 31px;
		line-height: 1.3;
		margin: 0 0 25px;
	}
	.testimonials-slider {
		width: 90%;
		margin: 0 auto;
		position: relative;
		padding: 0 0 65px;
	}
	.testimonials-slide p {
		font-size: 16px;
		line-height: 26px;
		letter-spacing: 0;
	}
	.testimonials-slider .swiper-button-next,
	.testimonials-slider .swiper-button-prev {
		width: 45px;
		height: 45px;
	}
	.testimonials-slider::after {
		right: -35px;
	}
	.digital-marketing-wrapper h2 {
		font-size: 31px;
		line-height: 1.3;
		margin: 0 0 25px;
	}
	.digital-marketing-tabs-nav {
		width: 50%;
		border-radius: 15px;
		padding: 25px;
	}
	.digital-marketing-tabs-nav-info {
		width: 55%;
		border-radius: 15px;
		padding: 20px;
	}
	.nav-tabs .nav-item a {
		font-size: 14px;
		line-height: 21px;
	}
	.nav-tabs .nav-item:not(:last-child) {
		margin-bottom: 25px;
	}
	.digital-marketing-tabs-content ul li {
		margin: 0 0 10px;
		padding: 0 0 0 25px;
		font-size: 14px;
		line-height: 17px;
	}
	.digital-marketing-tabs-content ul li::before {
		width: 16px;
		height: 16px;
	}
	.digital-marketing-tabs-content {
		width: 100%;
		padding: 0 0 0 25px;
		margin: 0;
	}
	.digital-marketing-tabs-content ul li ul li {
		padding-left: 0;
		width: 100%;
		list-style-type: disc;
		margin: 5px 0 0;
		font-size: 12px;
	}
	.nav-tabs .nav-item a.active::before {
		right: -4.3vw;
		top: 9px;
		width: 20px;
		height: 20px;
	}
	h2 {
		font-size: 31px;
		line-height: 40px;
		letter-spacing: 0;
	}
	.ready-section {
		padding: 50px 0;
	}
	.data-info-wrapper + .data-info-wrapper .data-info-left {
		margin-left: 0;
		margin-right: 0;
	}
	.data-info-right p br {
		display: none;
	}
}

@media (max-width: 575px) {
	.banner-section .container {
		padding: 0 15px;
		max-width: 100%;
	}
	.banner-wrapper {
		text-align: center;
		width: 325px;
		margin: 0 auto;
	}
	h1 {
		font-size: 31px;
		line-height: 39px;
		letter-spacing: 0px;
	}
	.banner-wrapper h1 {
		margin-bottom: 20px;
	}
	.banner-wrapper p {
		width: 100%;
		margin: 0 auto 40px;
		font-size: 16px;
		line-height: 26px;
	}
	.banner-section {
		padding-bottom: 45px;
	}
	.marketing-solutions-wrapper {
		justify-content: center;
	}
	.marketing-solutions-img {
		width: 60%;
		margin: 0 auto 35px;
	}
	.marketing-solutions-section {
		padding: 40px 0;
	}
	.marketing-solutions-info {
		width: 100%;
		text-align: center;
	}
	.brands-partner-row {
		justify-content: center;
		flex-wrap: wrap;
	}
	.brands-partner-logo {
		padding: 0 12px 25px;
		width: 33.33%;
	}
	.brands-partner-wrapper h4 {
		font-size: 20px;
		line-height: 30px;
		margin: 0 0 25px;
	}
	.data-info-section .container {
		max-width: 100%;
	}
	.data-info-wrapper {
		top: 0;
		justify-content: center;
	}
	.data-info-wrapper {
		transform: scale(1) !important;
	}
	.data-info-left {
		width: 250px;
		margin: 0 0 35px;
		order: -1;
	}
	.data-info-right {
		width: 100%;
		text-align: center;
		order: -2;
		margin: 0 0 15px;
	}
	.data-number {
		text-align: right;
		width: calc(100% - 50px);
	}
	.data-info-right h2 {
		font-size: 28px;
		line-height: 38px;
		letter-spacing: 1px;
		margin: 0 0 15px;
	}
	.data-info-wrapper + .data-info-wrapper .data-info-right {
		width: 100%;
	}
	.testimonials-section {
		padding: 40px 0;
	}
	.testimonials-slide {
		padding: 0 15px;
	}
	.testimonials-slider {
		width: 100%;
	}
	.container {
		max-width: 100%;
		padding: 0 15px;
	}
	.testimonials-slider .swiper-scrollbar {
		border-radius: 10px;
		height: 3px;
		width: 80%;
		margin-top: 25px;
	}
	.testimonials-slider::before {
		top: -20px;
		left: -5px;
		width: 35px;
		height: 60px;
	}
	.testimonials-slider::after {
		width: 35px;
		height: 60px;
		right: -5px;
		bottom: 50px;
	}
	.testimonials-slider .swiper-button-next {
		left: 59%;
	}
	.testimonials-slider .swiper-button-prev {
		left: 41%;
	}
	.digital-marketing-tabs-nav-info {
		width: 100%;
		border-radius: 15px;
		padding: 55px 20px 20px;
		position: relative;
		height: auto;
		margin-top: -22px;
	}
	.digital-marketing-tabs-nav {
		width: 100%;
		border-radius: 15px;
		padding: 18px 15px;
	}
	.digital-marketing-tabs-content {
		width: 100%;
		padding: 0;
		margin: 0;
	}
	a.tabs-dropdown {
		display: block;
		color: #fff;
		font-family: 'Gotham Book';
		font-weight: normal;
		font-size: 14px;
		text-align: left;
		padding: 0 20px 0 0;
		position: relative;
	}
	a.tabs-dropdown::before {
		content: '';
		position: absolute;
		top: 5px;
		right: 0;
		width: 8px;
		height: 8px;
		border: 1px solid #fff;
		border-right: 0;
		border-bottom: 0;
		transform: rotate(-135deg);
	}
	.nav-tabs {
		display: none;
		margin: 20px 0 0;
		border-top: 1px dashed #ffffff45;
		padding-top: 20px;
	}
	.digital-marketing-tabs-content ul li {
		margin: 0 0 20px;
	}
	a.tabs-dropdown.open::before {
		top: 10px;
		transform: rotate(45deg);
	}
	.ready-section {
		padding: 40px 0;
	}
	.ready-wrapper h2 span br {
		display: none;
	}
	h2 {
		font-size: 28px;
		line-height: 37px;
		letter-spacing: 0;
	}
	.ready-wrapper h2 {
		margin: 0 0 25px;
	}
	.hidemobile {
		display: none;
	}
	.hidedesktop {
		display: block;
	}
	ul.nav.nav-tabs.mobile-tabs {
		position: absolute;
		left: 0;
		right: 0;
		/* top: 36px; */
		z-index: 1;
		background: #32327a;
		transform: translateY(-2px);
	}
	.digital-marketing-tabs-nav.digital-marketing-tabs-active {
		border-radius: 15px 15px 0px 0px;
	}
	.nav-tabs .nav-item a {
		padding: 0 15px;
	}
	.nav-tabs .nav-item a.active::before {
		display: none;
	}
	.digital-marketing-tabs-nav-info .tab-content > .tab-pane {
		transition: unset !important;
	}
	.data-info-wrapper-d-flex {
		justify-content: center;
		padding: 20px 15px;
	}
	/* .data-info-wrapper:first-child {
        height: 100vh;
        align-items: center;
    } */
	.data-info-wrapper + .data-info-wrapper {
		transform: translateY(0);
	}
	.data-info-right p br {
		display: none;
	}
	.digital-marketing-wrapper .heading-animate span br {
		display: none;
	}
	.brands-partner-logo img {
		max-height: 50px;
	}
	.find-out-btn {
		padding-top: 25px;
		padding-bottom: 45px;
		margin: 0;
	}
	.data-info-right p strong {
		font-size: 22px;
	}
	.data-info-section {
		padding: 0;
		background: transparent;
	}
	.data-info-section .container {
		padding: 0;
	}
	.brands-partner-row.mobile-hide {
		display: none;
	}
	.brands-partner-row.desktop-hide {
		display: flex;
		background: #000;
		padding: 20px 15px 0;
		margin: -1px 0 0;
	}
	.brands-partner-section {
		padding: 0;
	}
	.data-number p.align-left {
		text-align: right;
	}

	.contact-form input,
	.contact-form select,
	.contact-form textarea,
	#mktChallengesPlaceholder {
		padding: 1rem 1.25rem;
		font-size: 1rem;
	}

	.multiple-select-label,
	.custom-checkbox {
		font-size: 1rem;
	}

	.custom-checkbox label {
		column-gap: 1rem;
	}

	.custom-checkbox .checkmark {
		flex: 0 0 32px;
		height: 32px;
	}

	.multiple-select-content {
		padding: 1.125rem 1.25rem;
	}

	.multiple-select-content > div:not(:last-child) {
		margin-bottom: 1rem;
	}

	.submit-button {
		min-height: 51px;
		font-size: 18px;
	}
}

@media (min-width: 577px) {
	.modal-dialog {
		max-width: 600px;
	}
}

@media (min-width: 768px) {
	.contact-form {
		width: calc(100% - 6rem);
		margin: 0 auto;
	}
}
